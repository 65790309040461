import {
    authorize,
    createSession,
    getSession,
    resumeSession,
    updateSession
} from '../../api.js';
import i18n from '../../i18n.js';
import './PaymentModal.css';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaXmark, FaCopy } from 'react-icons/fa6';
import Modal from 'react-modal';
import { Tabs } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
    CardElement,
    Elements,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PK);

const CheckoutForm = ({ email, setEmail, closeModal }) => {
    const [activeTab, setActiveTab] = useState('new-session');
    const [sessionID, setSessionID] = useState('');

    const elements = useElements();
    const stripe = useStripe();
    const { t } = useTranslation();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const notification = notifications.show({
            autoClose: false,
            color: 'yellow',
            loading: true,
            message: i18n.t('toast.authorizing', {
                amount: '25.00'
            }),
            title: i18n.t('toast.authorization'),
            withCloseButton: false
        });

        await createSession(email)
            .then(async (session) => {
                const authorization = await authorize(
                    session.id,
                    email,
                    process.env.REACT_APP_AMOUNT,
                    false
                );

                return { authorization, session };
            })
            .then(async ({ authorization, session }) => {
                const stripeAuth = await stripe.confirmCardPayment(
                    authorization.intent,
                    {
                        payment_method: {
                            card: elements.getElement(CardElement),
                            billing_details: {
                                name: email
                            }
                        }
                    }
                );

                return { authorization, session, stripeAuth };
            })
            .then(async ({ authorization, session, stripeAuth }) => {
                if (stripeAuth.error) {
                    notifications.update({
                        id: notification,
                        autoClose: 3000,
                        color: 'red',
                        icon: <FaXmark />,
                        loading: false,
                        message: i18n.t('toast.authorization_failure'),
                        title: i18n.t('toast.authorization')
                    });
                    throw new Error(stripeAuth.error.message);
                } else {
                    try {
                        const update = await updateSession(session.id, {
                            id: session.id,
                            email: email,
                            stripe_id: authorization.stripe_id,
                            stripe_intent: stripeAuth.paymentIntent.id,
                            status: true
                        });

                        closeModal();
                        localStorage.setItem('session_id', session.id);
                        notifications.update({
                            id: notification,
                            autoClose: 3000,
                            color: 'green',
                            icon: <FaCheck />,
                            loading: false,
                            message: i18n.t('toast.authorized'),
                            title: i18n.t('toast.authorization')
                        });

                        return { update };
                    } catch (error) {
                        console.error(error.message);
                        notifications.update({
                            id: notification,
                            autoClose: 3000,
                            color: 'red',
                            icon: <FaXmark />,
                            loading: false,
                            message: i18n.t('toast.update_session_failure'),
                            title: i18n.t('toast.authorization')
                        });
                        throw error;
                    }
                }
            })
            .catch(async (error) => {
                console.error(error);
                notifications.update({
                    id: notification,
                    autoClose: 3000,
                    color: 'red',
                    icon: <FaXmark />,
                    loading: false,
                    message: i18n.t('toast.create_session_failure'),
                    title: i18n.t('toast.authorization')
                });

                await resumeSession({
                    recipient: [email],
                    subject: `${i18n.t('session.subject')}`,
                    text: `${i18n.t('session.text')}`
                }).then(() => {
                    setActiveTab('existing-session');
                });
            });
    };

    const handleResume = async (event) => {
        event.preventDefault();
        await getSession(sessionID).then((session) => {
            if (session.status) {
                localStorage.setItem('session_id', session.id);
                closeModal();
            }
        });
    };

    const handleCopyTestCard = () => {
        const testCardNumber = '4242 4242 4242 4242';
        navigator.clipboard
            .writeText(testCardNumber)
            .then(() => {
                notifications.show({
                    autoClose: 7000,
                    color: 'green',
                    loading: false,
                    message:
                        'Paste card number below, fill other fields with; Expiration: 04/44 CVC: 444 Zip: 44444',
                    title: 'Test Card Copied',
                    withCloseButton: false
                });
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <>
            {stripe ? (
                <Tabs
                    value={activeTab}
                    onTabChange={setActiveTab}
                    style={{ width: '100%', height: '100%' }}
                >
                    <Tabs.List
                        style={{ width: '100%', justifyContent: 'center' }}
                    >
                        <Tabs.Tab
                            onClick={() => setActiveTab('new-session')}
                            value="new-session"
                            className="tab-text"
                        >
                            {t('payment.new_session')}
                        </Tabs.Tab>
                        <Tabs.Tab
                            onClick={() => setActiveTab('existing-session')}
                            value="existing-session"
                            className="tab-text"
                        >
                            {t('payment.existing_session')}
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="new-session">
                        <form onSubmit={handleSubmit} className="payment-form">
                            <div>
                                <button
                                    className="copy-button"
                                    type="button"
                                    onClick={() => handleCopyTestCard()}
                                >
                                    <FaCopy style={{ margin: '8px' }} />
                                    Copy Test Card
                                </button>
                            </div>
                            <div className="card-element">
                                <CardElement />
                            </div>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={t('email')}
                                className="form-input"
                            />
                            <button
                                type="submit"
                                disabled={!stripe}
                                className="form-button"
                            >
                                {t('payment.authorize')}
                            </button>
                        </form>
                    </Tabs.Panel>

                    <Tabs.Panel
                        value="existing-session"
                        style={{ height: '100%' }}
                    >
                        <form onSubmit={handleResume} className="payment-form">
                            <p>{t('payment.session')}</p>
                            <input
                                type="id"
                                value={sessionID}
                                onInput={(e) => setSessionID(e.target.value)}
                                placeholder={t('payment.session_id')}
                                className="form-input"
                            />
                            <button type="submit" className="form-button">
                                {t('continue')}
                            </button>
                        </form>
                    </Tabs.Panel>
                </Tabs>
            ) : (
                <div>{t('loading')}</div>
            )}
        </>
    );
};

CheckoutForm.propTypes = {
    closeModal: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired
};

const PaymentForm = ({ isModalOpen, closeModal, email, setEmail }) => (
    <Modal
        className="payment-modal"
        contentLabel="Select Styles"
        isOpen={isModalOpen}
        onRequestClose={closeModal}
    >
        <Elements stripe={stripePromise}>
            <CheckoutForm
                closeModal={closeModal}
                email={email}
                setEmail={setEmail}
            />
        </Elements>
    </Modal>
);

PaymentForm.propTypes = {
    closeModal: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    setEmail: PropTypes.func.isRequired
};

export default PaymentForm;
